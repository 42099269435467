import { useEffect, useState, type ComponentType, memo } from "react"
import { useMounted } from "./useMounted.tsx"

export function withCurrentRoute(Component): ComponentType {
    return memo((props) => {
        const mounted = useMounted()
        if (!mounted) return <Component {...props} />

        const { current, variant: defaultVariant, ...restProps } = props
        const currentPaths = current.split(",")
        const pathname = window.location.pathname

        const variant = currentPaths.some(
            (cur) =>
                (cur === "/home" && pathname === "/") || pathname.includes(cur)
        )
            ? "Open"
            : defaultVariant

        return <Component {...restProps} variant={variant} />
    })
}
